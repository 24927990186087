import { WIDTH, HEIGHT, FONTS, SCENES_NAMES, FONTS_SIZES } from '../constant/config';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;
const COLOR_BLUE = Phaser.Display.Color.HexStringToColor('#00B8DE').color;
const COLOR_PINK = Phaser.Display.Color.HexStringToColor('#D40F7D').color;

export default class BootScene extends Phaser.Scene
{
    
    // private playButton: Phaser.GameObjects.Text;
    private playButton: RexUIPlugin.Buttons;
    private rexUI: RexUIPlugin;


    constructor ()
    {
        super(SCENES_NAMES.BOOT);
    }

    public init()
    {

    }

    public preload()
    {
        
        this.load.bitmapFont('galaxy', './assets/fonts/galaxy8.png', './assets/fonts/galaxy8.xml');

        this.load.image('logo', './assets/graphics/backgrounds/background.png');
        this.load.image('bg', './assets/graphics/backgrounds/bgBootScene.png');

        this.load.image('progressBar', './assets/graphics/progress-bar.png');
        this.load.image('whitePixel', './assets/graphics/whitePixel.png');
    }

    public create()
    {
        
        this.scale.setParentSize(window.innerWidth, window.innerHeight);
        
        this.add.image(0, 0, 'bg')
        .setOrigin(0, 0).setDisplaySize(WIDTH, HEIGHT);
        
        if (this.sys.game.device.os.desktop){
            console.log("desktop");
            this.playButton = this.rexUI.add.buttons ({
                x: WIDTH/2,
            y: 2.2 * HEIGHT/4,

            orientation: 'x',
            // background: this.rexUI.add.roundRectangle(0, 0, 0, 0, 20, COLOR_DARK),
            buttons: [
                this.createButton().setOrigin(0.5, 0.5),
            ],
            space: {
                left: 0, right: 0, top: 0, bottom: 0,
                item: 6
            }
            
        })
        .setOrigin(0.5, 0.5)
        .layout()
        .setInteractive({ useHandCursor: true })
        .on('pointerup', () => this.scene.run(SCENES_NAMES.LOAD))
        .on('pointerover', () => this.playButton.setScale(1.1))
        .on('pointerout', () => this.playButton.setScale(1))
        ;
    } else {
        console.log("mobile");
        this.playButton = this.rexUI.add.buttons ({
            x: WIDTH/2,
        y: 2.2 * HEIGHT/4,

        orientation: 'x',
        // background: this.rexUI.add.roundRectangle(0, 0, 0, 0, 20, COLOR_DARK),
        buttons: [
            this.createButton().setOrigin(0.5, 0.5),
        ],
        space: {
            left: 0, right: 0, top: 0, bottom: 0,
            item: 6
        }
        
    })
    .setOrigin(0.5, 0.5)
    .layout()
    .setInteractive()
    .on('pointerup', () => this.scene.run(SCENES_NAMES.LOAD))
    .on('pointerdown', () => this.playButton.setScale(1.1))
    ;
    }
    }

    createButton() {
        return this.rexUI.add.label({
            x:WIDTH/2,
            y:WIDTH/2,
            width: 60,
            height: 20,
            background: this.rexUI.add.roundRectangle(0, 0, 100, 30, 10, COLOR_PINK),
            text: this.add.text(0, 0, 'PLAY', {
                fontSize: 18 
            }),
            align: 'center',
            space: {
                left: 0,
                right: 0,
            }
        })
    }
}
