import { FONTS, FONTS_SIZES, HEIGHT, SCENES_NAMES, WIDTH } from "../constant/config";
const COLOR_BLUE = Phaser.Display.Color.HexStringToColor('#00B8DE').color;
const COLOR_PINK = Phaser.Display.Color.HexStringToColor('#D40F7D').color;

export default class LoadScene extends Phaser.Scene
{
    constructor()
    {
        super({
            key: SCENES_NAMES.LOAD,
            active: false,
            visible: false,
            pack: { //  Splash screen and progress bar textures.
                files: [{
                    key: 'logo',
                    type: 'image'
                },
                {
                    key: 'progressBar',
                    type: 'image'
                }]
            }
        });
    }

    public preload()
    {
        // Display cover and progress bar textures.
        this.showCover();
        this.showProgressBar();

        // Preload assets here
        this.load.image('coins', './assets/graphics/tilesets/coins_extruded.png');
        this.load.image('city1', './assets/graphics/tilesets/city1_extruded.png');
        this.load.image('city4', './assets/graphics/tilesets/city4_extruded.png');
        this.load.image('colliderTileset', './assets/graphics/tilesets/colliderTileset_extruded.png');
        // this.load.image('sport', './assets/graphics/tilesets/sport.png')
        this.load.tilemapTiledJSON('map1', './assets/maps/map1.json')
        // Preload Json
        this.load.json('jsondataPersonnages', '/personnages');
        this.load.json('jsondataInformations', '/informations');

        // Tiles des personnages
        this.load.image('casque_face', './assets/graphics/personnages/casque_face.png');
        this.load.image('chauve_face', './assets/graphics/personnages/chauve_face.png');
        this.load.image('brunette_face', './assets/graphics/personnages/brunette_face.png');
        this.load.image('garcon_face', './assets/graphics/personnages/garcon_face.png');
        this.load.image('fille_face', './assets/graphics/personnages/fille_face.png');
        this.load.image('papy_face', './assets/graphics/personnages/papy_face.png');
        this.load.image('casque_droite', './assets/graphics/personnages/casque_droite.png');
        this.load.image('chauve_droite', './assets/graphics/personnages/chauve_droite.png');
        this.load.image('brunette_droite', './assets/graphics/personnages/brunette_droite.png');
        this.load.image('garcon_droite', './assets/graphics/personnages/garcon_droite.png');
        this.load.image('fille_droite', './assets/graphics/personnages/fille_droite.png');
        this.load.image('papy_droite', './assets/graphics/personnages/papy_droite.png');
        this.load.image('casque_gauche', './assets/graphics/personnages/casque_gauche.png');
        this.load.image('chauve_gauche', './assets/graphics/personnages/chauve_gauche.png');
        this.load.image('brunette_gauche', './assets/graphics/personnages/brunette_gauche.png');
        this.load.image('garcon_gauche', './assets/graphics/personnages/garcon_gauche.png');
        this.load.image('fille_gauche', './assets/graphics/personnages/fille_gauche.png');
        this.load.image('papy_gauche', './assets/graphics/personnages/papy_gauche.png');

        // board pour infos des batîments
        this.load.image('small_board', './assets/graphics/information_boards/small_board.png');
        this.load.image('big_board', './assets/graphics/information_boards/big_board.png');

        // sound
        this.load.audio('audio', './assets/sounds/LudumDare38-Track1.wav');
        this.load.audio('coinSound', './assets/sounds/confirm_style_2_002.wav');
        this.load.audio('typing', './assets/sounds/quick-mechanical-keyboard-14391.mp3');


        // spritesheet du personnage
        // this.load.spritesheet("taureau", "./assets/graphics/taureaux.png", { frameWidth: 16, frameHeight: 16 });
        this.load.spritesheet("playerSprite", "./assets/graphics/playerSprite.png", { frameWidth: 16, frameHeight: 16 });

        // sound icon 
        this.load.image('soundOnImage', './assets/graphics/SoundOn.png');
        this.load.image('noSoundImage', './assets/graphics/no-sound.png');

        this.load.image('cross', './assets/graphics/cross.png')

    }

    public create(): void
    {
        // Create anims here

    }

    public shutdown(): void
    {
        this.load.removeAllListeners(Phaser.Loader.Events.PROGRESS);
        this.load.removeAllListeners(Phaser.Loader.Events.COMPLETE);
    }

    private showCover()
    {
        // this.add.image(WIDTH/4, HEIGHT/4, 'logo')
        //     .setOrigin(0, 0)
        //     .setDisplaySize(WIDTH/2, HEIGHT/2);

        this.add.image(WIDTH / 2, HEIGHT / 5 * 4 + 8, 'whitePixel')
            .setDisplaySize(242, 18);
    }

    private showProgressBar()
    {
        //  Get the progress bar filler texture dimensions.
        const { width: w, height: h } = this.textures.get('progressBar').get();

        //  Place the filler over the progress bar of the splash screen.
        const img = this.add.sprite(WIDTH / 2, HEIGHT / 5 * 4, 'progressBar').setOrigin(0.5, 0);

        // Add percentage text
        const loadingpercentage = this.add.bitmapText(WIDTH / 2, HEIGHT / 5 * 4 - 10, FONTS.GALAXY, 'loading:', FONTS_SIZES.GALAXY, 1)
            .setOrigin(0.5, 0.5)
            .setName('loadingpercentage')
            .setAlpha(1).setTintFill(COLOR_PINK);

        //  Crop the filler along its width, proportional to the amount of files loaded.
        this.load
            .on(Phaser.Loader.Events.PROGRESS, (v: number) =>
            {
                loadingpercentage.text = `loading: ${Math.round(v * 100)}%`;
                img.setCrop(0, 0, Math.ceil(v * w), h);
            })
            .on(Phaser.Loader.Events.COMPLETE, () =>
            {
                this.scene.stop(SCENES_NAMES.BOOT);

                this.scene.start(SCENES_NAMES.GAME);
            });
    }
}
