import { Sound } from "phaser";
import GameScene from "../scenes/GameScene";
import { TEntityConfig } from "../types/types";

export class Entity extends Phaser.GameObjects.Sprite
{
    public scene: GameScene;
    public body: Phaser.Physics.Arcade.Body;

    constructor(config: TEntityConfig)
    {
        super(config.scene, config.x, config.y, config.texture, config.frame);

        this.scene.add.existing(this);

        this.scene.physics.world.enable(this);

        this.body.setCollideWorldBounds(true, 0, 0, true)
            .setBounce(0, 0)
            .setAllowGravity(false)
            .setFriction(0, 0);

    }

    public preUpdate(time: number, delta: number)
    {
        super.preUpdate(time, delta);

        const {left, right, up, down, space} = this.scene.cursors
        let pointer =  this.scene.input.activePointer;

        
        if (this.scene.textBox.visible === true || this.scene.answersGrid?.visible === true) {
            this.body.setVelocity(0);
            return;
        }
        
        if (this.scene.sys.game.device.os.desktop) {
        
        if(left.isDown)
        {
            // console.log('left');
            this.body.setVelocityX(-75);
            !this.anims.isPlaying && this.anims.play('left', false);

            // this.scene.sound.play('walk');
            
        }
        else if(right.isDown )
        {
            // console.log('right');
            this.body.setVelocityX(75);
            !this.anims.isPlaying && this.anims.play('right', false);
        }
        else
        {
            this.body.setVelocityX(0);
        }

        if(up.isDown)
        {
            // console.log('up');
            this.body.setVelocityY(-75);

            !this.anims.isPlaying && this.anims.play('back', false);
        }
        else if(down.isDown)
        {
            // console.log('down');
            this.body.setVelocityY(75);

            !this.anims.isPlaying && this.anims.play('front', false);
        }
        else
        {
            this.body.setVelocityY(0);
        }

    } else {
        if (pointer.isDown) {
            let touchX = pointer.worldX;
            let touchY = pointer.worldY;


            if(touchX < this.body.x)
            {
                // console.log('left');
                this.body.setVelocityX(-70);
                !this.anims.isPlaying && this.anims.play('left', false);
    
                // this.scene.sound.play('walk');
                
            }
            else if(touchX > this.body.x)
            {
                // console.log('right');
                this.body.setVelocityX(70);
                !this.anims.isPlaying && this.anims.play('right', false);
            }
            else 
            {
                this.body.setVelocityX(0);
            }
    
            if(touchY < this.body.y)
            {
                // console.log('up');
                this.body.setVelocityY(-70);
    
                !this.anims.isPlaying && this.anims.play('back', false);
            }
            else if(touchY > this.body.y)
            {
                // console.log('down');
                this.body.setVelocityY(70);
    
                !this.anims.isPlaying && this.anims.play('front', false);
            }
            else 
            {
                this.body.setVelocityY(0);
            }
        } else if (!pointer.isDown){
            this.body.setVelocity(0);
        }
    }
}
}
