import { FONTS, HEIGHT, SCENES_NAMES, WIDTH } from '../constant/config';
import { Entity } from '../entities/Entity.js';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import { Click, Label, TextBox } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import { Animations, Cameras, Events, GameObjects, Scene, Scenes } from 'phaser';
import { FONTS_SIZES } from '../constant/config';
import GridTable from 'phaser3-rex-plugins/plugins/gridtable.js';


const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;

export interface PersonnagesResponse {
    personnages: Personnage[];
}

export interface InformationsResponse {
    informations: Information[];
}

export interface Personnage {
    id: number;
    positionX: number;
    positionY: number;
    name: string;
    text: string;
    speakerRole: string;
    choices: Choice[];
}

export interface Choice {
    id: number;
    title: string;
    text: string;
}

export interface Information {
    id: number;
    positionX: number;
    positionY: number;
    text: string;
    board: string;
}



export default class GameScene extends Phaser.Scene {
    private map: Phaser.Tilemaps.Tilemap;
    private colliderLayer: Phaser.Tilemaps.TilemapLayer;
    private zoningLayer: Phaser.Tilemaps.TilemapLayer;
    private buildingsLayer: Phaser.Tilemaps.TilemapLayer;
    private topLayer: Phaser.Tilemaps.TilemapLayer;
    private backgroundLayer: Phaser.Tilemaps.TilemapLayer;
    private floorsLayer: Phaser.Tilemaps.TilemapLayer;
    public textBox: RexUIPlugin.TextBox;
    public textBoxName: RexUIPlugin.TextBox;

    private minimap: Phaser.Cameras.Scene2D.Camera;
    private rexUI: RexUIPlugin;  // Declare scene property 'rexUI' as RexUIPlugin type
    private rexGridTable: RexUIPlugin.GridTable;

    private jsondataPerso: PersonnagesResponse;
    private jsondataInfo: InformationsResponse;

    private player: Phaser.GameObjects.Sprite;
    private characters: Phaser.GameObjects.Sprite[];
    private boards: Phaser.GameObjects.Sprite[];
    private fences: Phaser.GameObjects.Sprite[];

    public cursors: Phaser.Types.Input.Keyboard.CursorKeys;

    public taureau: Phaser.GameObjects.Sprite;
    public playerSprite: Phaser.GameObjects.Sprite;

    private charactersSeen = 0;
    private percentageComplete;
    private score = 0;
    private coinsNumber;
    private endMessage = false; 

    private textCampusZone: GameObjects.Text;
    private spaceClick: GameObjects.Text;


    private soundEnabled: boolean = true;
    public answersGrid: RexUIPlugin.GridButtons | null;

    constructor() {

        super({
            key: SCENES_NAMES.GAME,
            active: false,
            visible: false,
        });
    }

    public init(data: any) {

    }

    public async create() {

        // create cursors keys
        this.cursors = this.input.keyboard!.createCursorKeys();

        this.map = this.make.tilemap({ key: 'map1', tileWidth: 16, tileHeight: 16 });

        this.getData();

        this.createLayer();

        this.createPlayer();

        this.createAnimation();

        this.createBoards();

        this.createCharacters();

        this.createTextBox();

        this.createMinimap();

        // installation d'un plugin pour faire fonctionner les tuiles animées de Tiled mais soucis pour reconnaître le type
        /** @ts-ignore */
        this.animatedTiles.init(this.map);

        // play the sound when the game begin
        this.sound.add("audio", { loop: true }).setVolume(0.3).play();
        this.sound.add("typing").setVolume(0.3)

        if (this.sys.game.device.os.desktop){
        // Change page and close textBox with space touch
        this.input.keyboard?.on('keydown-SPACE', () => {
            // console.log('testSpace')
            if (this.textBox.visible === true) {
                
                if (this.textBox.isTyping === true) {
                    this.textBox.stop(true);
                    return;
                }
                if (this.textBox.isLastPage) {
                    this.textBox.setActive(false).setVisible(false);
                    this.textBoxName.setVisible(false);
                    this.answersGrid?.setVisible(true);
                    this.sound.stopByKey('typing')
                    return;
                };
                
                this.textBox.typeNextPage();
                this.sound.play('typing');
            }
        }) 
    } else {
            this.input.on('pointerup', () => {
                this.spaceClick.setVisible(false)
                // console.log('testSpace')
                if (this.textBox.visible === true) {
                    
                    if (this.textBox.isTyping === true) {
                        this.textBox.stop(true);
                        return;
                    }
                    if (this.textBox.isLastPage) {
                        this.textBox.setActive(false).setVisible(false);
                        this.textBoxName.setVisible(false);
                        this.answersGrid?.setVisible(true);
                        return;
                    };
    
                    this.textBox.typeNextPage();
                    this.sound.play('typing');
                }
            })
        }

        // collision with tiles with property 'collides' and others
        this.colliderLayer.setCollisionByProperty({ collides: true,  });
        this.topLayer.setCollisionByProperty({ fences: true, coins: false });
        this.zoningLayer.setCollisionByProperty({claviere: true, croupillac: true, meuh: true})

        // affichage du pourcentage de personnages rencontrés
        this.percentageComplete = this.add.bitmapText(WIDTH - 25, 10,
            FONTS.GALAXY, '0%',
            FONTS_SIZES.GALAXY,
        ).setDepth(1000).setVisible(true).setScrollFactor(0, 0);

        // collision between player and characters
        this.characters.forEach(character => {
            let seen = false;
            this.physics.add.collider(this.player, character,
                (_player, _character) => {

                    if (!seen) {
                        this.charactersSeen += 1;
                        console.log(this.charactersSeen);
                    }
                    seen = true;
                    // on donne la variable data qui contient les valeurs du personnage 
                    const character = _character as Phaser.GameObjects.Sprite;
                    const data = character.data.values as Personnage;
                    console.log(data.id);

                    if (this.textBox.visible === false) {
                        this.textBox.setVisible(true).start(data.text, 50);
                        this.createNameTextBox(data);
                        this.sound.play('typing');

                        if (data.choices?.length) {
                            this.createGridButtons(data);
                        }
                    };
                });
            });
            

        // collision between player and boards
        this.boards.forEach(board => {
            this.physics.add.collider(this.player, board,
                (_player, _board) => {
                    // on donne la variable data qui contient les valeurs du personnage 
                    const board = _board as Phaser.GameObjects.Sprite;
                    const data = board.data.values as Information;
                    console.log(data.id);
                    if (this.textBox.visible === false) {
                        this.textBox.setVisible(true).start(data.text, 50);
                        this.sound.play('typing');
                    }
                });
        });

        this.textBox.on('pageend', () => {
            this.sound.stopByKey('typing');
        })
        // // collision between player and colliderLayer juste sur le côté gauche 
        this.physics.add.collider(this.player, this.colliderLayer);

        // fences qui prend que le contour de la bordur est un object sur tiled, le préciser? 
        // cf la creation de objectlayer dans la fonction tout en bas
        this.fences.forEach(e => this.physics.add.collider(this.player, e));

        // affichage du nombre de pièces récoltées
        this.coinsNumber = this.add.bitmapText(5, 25,
            FONTS.GALAXY, '0/30',
            FONTS_SIZES.GALAXY,
        ).setDepth(1000).setVisible(true).setScrollFactor(0, 0);

        // grab coins on the map
        this.physics.add.overlap(this.player, this.topLayer,
            (_player, _tile) => {
                const tile = _tile as Phaser.Tilemaps.Tile;

                if (tile.alpha == 0) {
                    return;
                }

                if (tile.properties.coins) {

                    this.sound.add("coinSound", { loop: false }).setVolume(0.3);
                    this.sound.play("coinSound");
                    tile.alpha = 0;
                    this.score += 1;
                }
            })

        this.textCampusZone = this.add.text(WIDTH/2, 10, '').setScrollFactor(0, 0).setDepth(1000).setOrigin(0.5);

        // display campus name when overlaping the zone
        this.physics.add.overlap(this.player, this.zoningLayer,
            (_player, _tile) => {
                const tile = _tile as Phaser.Tilemaps.Tile;

                if (tile.properties.claviere) {
                    this.textCampusZone.setText('CAMPUS CLAVIÈRES');
                } else
                if (tile.properties.croupillac) {
                    this.textCampusZone.setText('CAMPUS CROUPILLAC');
                } else
                if (tile.properties.meuh) {
                    this.textCampusZone.setText('MAISON DES ÉLÈVES');
                } else {
                    this.textCampusZone.setText('');
                }
            })

        // music on/off with sound icon on/off
        const soundButton = this.add.image(15, 13, 'soundOnImage')
            .setScrollFactor(0, 0)
            .setInteractive()
            .setDepth(10000)
            .setScale(0.08)
            .on('pointerdown', () => {
                this.toggleSound();
                soundButton.setTexture(this.soundEnabled ? 'soundOnImage' : 'noSoundImage');
            });
            this.minimap.ignore([soundButton, this.percentageComplete, this.coinsNumber, this.textCampusZone])

        // world bounds set to map size
        this.physics.world.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels)
            .setBoundsCollision(true, true, true, true);

        // camera is blocked by the map
        this.cameras.main.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);

    }

    public update(time: number, delta: number): void {
        //  permet de gérer jusqu'où se déplace la camera avec le player sur la map
        if (!this.player) return;
        this.minimap.scrollX = Phaser.Math.Clamp(this.player.x, 0, 3000)
        this.minimap.scrollY = Phaser.Math.Clamp(this.player.y, 0, 2000)

        this.minimap.setVisible(!(this.textBox.visible || this.answersGrid?.visible));

        this.percentageComplete.setText(`${Math.round(100 * this.charactersSeen / this.characters.length)}%`);

        this.coinsNumber.setText(this.score + '/30');

        if(this.charactersSeen === this.characters.length) {
            if(this.endMessage === false) {
                if (this.textBox.visible === false) {

                    this.textBox.setVisible(true).start('Bravo ! Tu as rencontré tous les personnages du campus. Si tu souhaites en savoir plus sur l\'école et aller plus loin, n\'hésite pas à consulter les liens ci-dessous. Bonne visite !', 50);
                    this.textBox.setPosition(25, HEIGHT/2);
                    this.sound.play('typing');
                    this.endMessage = true;
                }
            }
    };
    }

    getData() {
        // test get data with load function
        this.jsondataPerso = this.cache.json.get('jsondataPersonnages');
        this.jsondataInfo = this.cache.json.get('jsondataInformations');
    }

    createPlayer() {

        this.player = new Entity({
            scene: this,
            x: WIDTH / 2,
            y: HEIGHT / 2,
            texture: 'playerSprite'
        });
        console.log(this.player);
        // change player size
        this.player.setDisplaySize(16, 16);

        // set main camera to follow player
        this.cameras.main.startFollow(this.player)

        // position of the player
        this.player.setPosition(1425, 1205);

        // z-index of the player
        this.player.depth = 100;
    }

    createAnimation() {

        this.anims.create({
            key: "front",
            frameRate: 7,
            frames: this.anims.generateFrameNumbers("playerSprite", { start: 0, end: 2 }),
            repeat: 0
        });

        this.anims.create({
            key: "left",
            frameRate: 7,
            frames: this.anims.generateFrameNumbers("playerSprite", { start: 3, end: 5 }),
            repeat: 0
        });

        this.anims.create({
            key: "right",
            frameRate: 7,
            frames: this.anims.generateFrameNumbers("playerSprite", { start: 6, end: 8 }),
            repeat: 0
        });

        this.anims.create({
            key: "back",
            frameRate: 7,
            frames: this.anims.generateFrameNumbers("playerSprite", { start: 9, end: 11 }),
            repeat: 0
        });

        this.playerSprite = this.add.sprite(WIDTH, HEIGHT, "playerSprite");
        this.playerSprite.play("front");
    }

    createCharacters() {
        this.characters = this.jsondataPerso.personnages.map(element => {
            const sprite = this.physics.add.staticSprite(element.positionX, element.positionY, element.name)
                .setDisplaySize(16, 16);
            sprite.depth = 100;
            // on dit que les data du sprite sont celles de l'élément qui est égale aux infos en base de donnée
            sprite.setData(element);
            return sprite;
        });
    }

    createBoards() {
        this.boards = this.jsondataInfo.informations.map(element => {
            const board = this.physics.add.staticSprite(element.positionX, element.positionY, element.board)
                .setDisplaySize(16, 16);
            board.depth = 100;
            // on dit que les data du board sont celles de l'élément qui est égale aux infos en base de donnée
            board.setData(element);
            return board;
        });
    }

    createMinimap() {
        this.minimap = this.cameras.add(WIDTH - 100, HEIGHT - 70, 100, 70).setZoom(0.15).setName('mini')
        this.minimap.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);
        this.minimap.setBackgroundColor('rgb(0,100,0)');
        this.minimap.scrollX = 50;
        this.minimap.scrollY = 35;
    }

    createButton(text: string) {
        return this.rexUI.add.label({
            orientation: 'x',
            background: this.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT).setStrokeStyle(2, COLOR_PRIMARY),
            text: this.rexUI.wrapExpandText(this.add.text(0, 0, text,{
                fontSize: '9px',
                align: 'center', 
                
            })),
            expandTextWidth: true,
            space: {
                left: 3, right: 3, 
                text: 0,
            }
            
        })
    };

    createGridButtons(data: Personnage) {

        let btns = data.choices.map((choice) => this.createButton(choice.title))

        const btn_rows = Array.from({ length: Math.ceil(btns.length / 2) }, (_, i) => [btns[i * 2], btns[i * 2 + 1]].filter((i) => i))
        
        this.answersGrid = this.rexUI.add.gridButtons({
            x: WIDTH / 2, y: HEIGHT - HEIGHT/5,
            width: WIDTH, height: HEIGHT / 3,
            // background: this.rexUI.add.roundRectangle(0, 0, 20, 10, 10, COLOR_PRIMARY),
            buttons: btn_rows,
            space: {
                top: 0, bottom: 0,
                row: 3, column: 2
            }
        }).layout().setDepth(2000).setScrollFactor(0, 0).setVisible(false);

        
        this.answersGrid.on('button.click', (button, index) => {
            this.answersGrid?.setVisible(false);
            this.textBox.setVisible(true).start(data.choices[index].text, 50);
        })

        this.answersGrid.add(
            this.add.image(
                WIDTH - 10, 
                HEIGHT - 15 - HEIGHT/3,
                'cross', 
            ).setTint(0x260e04).setAngle(45).setDepth(2010).setScrollFactor(0, 0).setInteractive()
            .on('pointerup', () => {
                this.answersGrid?.destroy();
                this.answersGrid = null;
            }
            ))
    }

    createTextBox() {
        this.textBox = this.rexUI.add.textBox({
            x: 25,
            y: 165,
            width: 210,
            height: 55,

            orientation: 0,

            background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 20, COLOR_PRIMARY)
                .setStrokeStyle(1, COLOR_LIGHT),
            text: this.rexUI.add.BBCodeText(0, 0, '', {
                fixedWidth: 190,
                fixedHeight: 45,

                fontSize: '11px',
                wrap: {
                    mode: 'word',
                    width: 180
                },
                maxLines: 3
            }).setResolution(1),

            space: {
                left: 10, right: 10,
                top: 10, bottom: 10,
                text: 5,
            },

        }).setOrigin(0).setDepth(1000)
            .layout().setVisible(false).setScrollFactor(0, 0)

        this.textBox.setInteractive();

        // création du text pour dire d'appuyer sur la barre d'espace 
        this.textBox.add(
           this.spaceClick = this.add.text(
                (WIDTH / 3),
                HEIGHT - 19,
                'espace pour passer',
                {
                    font: FONTS.GALAXY,
                    fontSize: FONTS_SIZES.GALAXY,
                }
            ).setDepth(1010).setVisible(true).setScrollFactor(0, 0).setResolution(1))
    }

    createNameTextBox(data: Personnage) {
        if (this.textBoxName) this.textBoxName.destroy();
        // création d'une textbox pour y entrer le nom du personnage qui parle 
        this.textBoxName =
            this.rexUI.add.textBox({
                x: 35,
                y: 155,
                // width: 50,
                height: 2,
                orientation: 0,
                background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 10, COLOR_LIGHT)
                    .setStrokeStyle(1, COLOR_PRIMARY),

                text: this.rexUI.add.BBCodeText(0, 0, data.speakerRole, {
                    // fixedWidth: 60,
                    // fixedHeight: 5,
                    fontSize: '8px',
                    maxLines: 1
                }).setResolution(1),

                space: {
                    left: 5,
                    right: 5,
                    top: 2,
                    bottom: 2,
                    text: 2,
                },
            }).setOrigin(0).setDepth(1001).layout().setVisible(true).setScrollFactor(0, 0)
    }

    toggleSound() {
        this.soundEnabled = !this.soundEnabled;
        if (this.soundEnabled) {
            this.sound.resumeAll();
        } else {
            this.sound.pauseAll();
        }
    }

    createLayer() {
        // add tilesets to map
        this.map.tilesets.forEach((tileset, i) => {
            this.map.addTilesetImage(this.map.tilesets[i].name, this.map.tilesets[i].name, 16, 16, 1, 2);
        });

        // layer that only handle collisions
        const colliderLayer = this.map.createLayer('collider', 'colliderTileset', 0, 0);

        if (colliderLayer === null) {
            throw new Error("no layer named collider ");
        }

        this.colliderLayer = colliderLayer;
        this.colliderLayer.alpha = 0;

        // layer of zoning of the different campus
        const zoningLayer = this.map.createLayer('zoning', 'colliderTileset', 0, 0);

        if (zoningLayer === null) {
            throw new Error("no layer named zoning");
        }

        this.zoningLayer = zoningLayer;
        this.zoningLayer.alpha = 0;

        // layer of buildings
        const buildingsLayer = this.map.createLayer('buildings', ['city1', 'city4'], 0, 0);

        if (buildingsLayer === null) {
            throw new Error("no layer named platform ");
        }

        this.buildingsLayer = buildingsLayer;
        this.buildingsLayer.alpha = 1;
        this.buildingsLayer.depth = 10;

        // layer of objects on top of layers
        const topLayer = this.map.createLayer('topLayer', ['city1', 'city4', 'coins'], 0, 0);

        if (topLayer === null) {
            throw new Error("no layer named platform ");
        }

        this.topLayer = topLayer;
        this.topLayer.alpha = 1;
        this.topLayer.depth = 90;

        // layer object for fences object from tile collision editor
        this.fences = this.map.createFromObjects('fencesLayer', [{
            name: 'fences',
        }]) as Phaser.GameObjects.Sprite[];
        this.fences.forEach(e => this.physics.add.existing(e, true));


        // layer for the floors
        const floorsLayer = this.map.createLayer('floors', ['city1', 'city4'], 0, 0);

        if (floorsLayer === null) {
            throw new Error("no layer named floors ");
        }

        this.floorsLayer = floorsLayer;
        this.floorsLayer.alpha = 1;
        this.floorsLayer.depth = 5;

        // layer background
        const backgroundLayer = this.map.createLayer('background', 'city4', 0, 0);

        if (backgroundLayer === null) {
            throw new Error("no layer named background ");
        }

        this.backgroundLayer = backgroundLayer;
        this.backgroundLayer.alpha = 1;
        this.backgroundLayer.depth = 1;
    }
}

