import { WIDTH, HEIGHT } from "./constant/config";
import BootScene from "./scenes/BootScene";
import GameScene from "./scenes/GameScene";
import LoadScene from "./scenes/LoadScene";
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import AnimatedTiles from 'phaser-animated-tiles-phaser3.5/dist/AnimatedTiles.min.js';



export const config: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    width: WIDTH,
    height: HEIGHT,
    pixelArt: true,
    autoRound: true,
    scale: {
        mode: Phaser.Scale.ScaleModes.FIT,
        autoRound: true,
        autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
    },
    physics: {
        default: 'arcade',
        arcade: {
            tileBias: 20,
            gravity: { x: 0, y: 0 },
            debug: false,
            debugShowBody: false,
            debugShowStaticBody: false,
        },
    },
    plugins: {
        scene: [{
            key: 'rexUI',
            plugin: RexUIPlugin,
            mapping: 'rexUI',
        }, {
            key: 'animatedTiles',
            plugin: AnimatedTiles,
            mapping: 'animatedTiles'
        },

        // ...
        ]
    },
    scene: [BootScene, LoadScene, GameScene],
    
};